import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class DomainApi extends BaseApi {
  getDomain(domain_url) {
    const localVarAxiosArgs = {
      url: `/appdomains/domain`,
      options: {
        method: 'GET',
        params: {
          domain_url,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
