import axios from 'axios';

import router from '@/router';
import store, { STORE_EVENT } from '@/store';

import AbusixApi from './abusix';
import AlertApi from './alert';
import DeliveryApi from './delivery';
import DmarcApi from './dmarc';
import DomainApi from './domain';
import EmailPreviewApi from './email-preview';
import NotificationApi from './notification';
import OpentrackerApi from './opentracker';
import OverviewApi from './overview';
import RdnsApi from './rdns';
import ReputationApi from './reputation';
import SetupApi from './setup';
import SndsApi from './snds';
import UserApi from './user';

const BASE_PATH =
  process.env.VUE_APP_API_BASE_URL || `${window.location.origin}/api`;

const globalAxios = axios.create({
  baseURL: BASE_PATH,
  withCredentials: true,
});

globalAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
      store.dispatch(STORE_EVENT.STOP_LOADING_ACCOUNT_PAGE);
      localStorage.clear();
      router.replace('/account/login');
    }
    return Promise.reject(error);
  }
);

const api = {
  AbusixApi: new AbusixApi(globalAxios),
  AlertApi: new AlertApi(globalAxios),
  DeliveryApi: new DeliveryApi(globalAxios),
  DmarcApi: new DmarcApi(globalAxios),
  DomainApi: new DomainApi(globalAxios),
  EmailPreviewApi: new EmailPreviewApi(globalAxios),
  NotificationApi: new NotificationApi(globalAxios),
  OpentrackerApi: new OpentrackerApi(globalAxios),
  OverviewApi: new OverviewApi(globalAxios),
  RdnsApi: new RdnsApi(globalAxios),
  ReputationApi: new ReputationApi(globalAxios),
  SetupApi: new SetupApi(globalAxios),
  SndsApi: new SndsApi(globalAxios),
  UserApi: new UserApi(globalAxios),
};

export default api;
